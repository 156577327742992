<template>
	<div class="about">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../assets/toimistopoyta.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Mistä työpaikkoja löytyy</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-5">
					<p><b>Linkkejä:</b><br>
					korkeakouluopiskelijoille ja vastavalmistuneille:<br>
					http://tyopaikat.contactforum.fi/</p>

					<p><b>LinkedIn:ssä on muun muassa seuraavia ryhmiä avoimiin työpaikkoihin ja uramahdollisuuksiin liittyen:</b><br>
					Uraristeys: https://www.linkedin.com/groups/6641351/<br>
					Ejobs: https://www.linkedin.com/groups/2609062/<br>
					Talent pool Finland: https://www.linkedin.com/groups/2399608/<br>
					Jobs in Nordic: https://www.linkedin.com/groups/4460342/</p>
					<p><b>Tee hakuvahteja esimerkiksi LinkedIn:iin.</b></p>
				</div>
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kolmeihmistatoimistotyopoydanaaressa.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Miten tehdä hyvä työhakemus</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-5">
					<p><b>Muistilista hyvän työhakemuksen laatimiseen</b><br><br>
					<button @click="open_link('https://duunitori.fi/tyoelama/tyonhakuopas/ohjeet-ja-mallit/hyva-tyohakemus')" class="btn btn-danger btn-t btn-danger-t">Duunitorilta:</button></p>

					<ol><li>Aloita vahvasti</li><li>Ole omaperäinen</li><li>Lisää vastaanottajan tiedot</li><li>Ole kohtelias ja ystävällinen</li><li>Älä kerro epäolennaisia asioita</li><li>Muista myydä oma osaamisesi</li><li>Älä toista samoja asioita kuin ansioluettelossa</li><li>Vastaa kysymykseen "miksi minut pitäisi valita"</li><li>Linkitä mukaan sosiaalisen median profiilisi</li><li>Tarkista kirjoitusvirheet</li></ol>

					<p>Lisää vinkkejä ja eri hakemustyyppejä Työmarkkinatorilta osoitteessa<br><br>
					<button @click="open_link('https://kokeile.tyomarkkinatori.fi/Etusivu/Henkiloasiakkaat/Tyonhaku/Vinkkeja-tyonhakuun')" class="btn btn-danger btn-t btn-danger-t" >tyomarkkinatori.fi</button> </p>
				</div>
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/tyopoytakuva.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Miten tehdä hyvä työhakemus</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 25px;">
				<div class="col"></div>				
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/miesasetteleekuviaseinalle.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5">
					<span class="align-middle"><a href="/tietoa/tunnista"><h2>Tunnista osaamisesi ja kiinnostuksesi</h2></a></span>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 25px;">
				<div class="col"></div>				
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/solmu.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5">
					<a href="/tietoa/piilo"><h2>Piilotyöpaikat ja palkkatietoa</h2></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 25px;">
				<div class="col"></div>				
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/tyohaastattelu.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5">
					<a href="/tietoa/haastattelu"><h2>Miten valmistautua haastatteluun</h2></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 25px;">
				<div class="col"></div>				
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kolmeihmistatoimistotyopoydanaaressa.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5">
					<a href="/tietoa/yrittajyys"><h2>Yrittäjyystietoa</h2></a>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import Social from '../components/Social.vue'

export default {
name: 'Tyonhaku',
components: {
	Social
	},
	methods: {
		open_link(link){
			console.log("open_link");
			window.open(link, "_blank");
	}
}
}
</script>

<style>
a{
	color: black;
}
</style>
